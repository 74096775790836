<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
            >
              <el-form-item label="垃圾中转站名称" prop="transferName" style="margin-bottom: 0px">
                <el-input v-model="form.transferName" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
              </el-form-item>
              <el-form-item label="开放时间" style="width: 100%">
                <el-time-select
                  placeholder="起始时间"
                  v-model="form.openStartTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                  }"
                ></el-time-select>至
                <el-time-select
                  placeholder="结束时间"
                  v-model="form.openEndTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00',
                    minTime: form.openStartTime
                  }"
                ></el-time-select>
              </el-form-item>
              <el-form-item label="垃圾中转站类型">
                <el-select v-model="form.transferType" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.transferType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="面积(m²)">
                <el-input v-model="form.area" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="管理配员(位)">
                <el-input v-model="form.demandWorker" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="每月水费(元)">
                <el-input v-model="form.waterFeeMonthly" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="每月电费(元)">
                <el-input v-model="form.electricityFeeMonthly" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <!-- <el-form-item label="垃圾压缩箱(个)">
                <el-input v-model="form.garbageCompactor" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>-->
              <el-form-item label="箱体品牌">
                <el-input v-model="form.brand" placeholder="请输入" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="产权" prop="propertyRights">
                <el-select v-model="form.propertyRights" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.propertyRights.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="出厂日期">
                <el-date-picker
                  v-model="form.productionDate"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="单箱">
                <el-checkbox v-model="form.isSingle">是</el-checkbox>
              </el-form-item>
              <el-form-item label="含有公厕">
                <el-checkbox v-model="form.isContainedWc"></el-checkbox>
              </el-form-item>
              <el-form-item label="高压清洗类型">
                <el-select
                  v-model="form.highPressureCleanType"
                  placeholder="请选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.highPressureCleanType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="用地手续是否完备" style="width:100%">
                <el-input
                  type="textarea"
                  :rows="2"
                  style="width:300px"
                  v-model="form.isCompletedFormalities"
                  placeholder="请输入用地手续的详情"
                  maxlength="254"
                ></el-input>
              </el-form-item>
              <el-form-item label="地址" style="width:100%">
                <el-input
                  style="width:300px"
                  v-model="form.address"
                  placeholder="请输入"
                  maxlength="254"
                ></el-input>
              </el-form-item>
              <!-- 垃圾中转站附件-->
              <el-form-item label="垃圾中转站附件" style="width: 100%">
                <ayl-upload-single-image v-model="form.transferImageUrls"></ayl-upload-single-image>
              </el-form-item>

              <!-- 垃圾压缩箱 -->
              <el-form-item label="垃圾压缩箱">
                <div class="add-btn">
                  <span style="color:orange">({{form.compactorList.length}})</span> 个
                  <el-button class="btn" style="margin-left: 20px" @click="onAddTab">+ 新增</el-button>
                </div>
              </el-form-item>
              <div class="add-item" v-for="(tab,index) in form.compactorList" :key="index">
                <div style="text-align:right;min-height:20px">
                  <span
                    v-if="index!=0"
                    style="display:inline-block;padding: 10px 15px;width:40px;cursor:pointer"
                    @click="onDeleteTab(tab)"
                  >
                    <i class="el-icon-close"></i>
                  </span>
                </div>
                <div class="add-item-main">
                  <el-form-item
                    label="编号"
                    :prop="'compactorList.'+index+'.garbageCompactorId'"
                    :rules="compactorListRules.garbageCompactorId"
                  >
                    <el-input v-model="tab.garbageCompactorId" placeholder="请输入" maxlength="20"></el-input>
                  </el-form-item>
                  <el-form-item label="类别">
                    <el-select v-model="tab.type" placeholder="请选择" filterable>
                      <el-option
                        v-for="(item, idx) in  $enums_hw.garbageType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="容积">
                    <el-select v-model="tab.volume" placeholder="请选择" filterable>
                      <el-option
                        v-for="(item, idx) in $enums_hw.garbageVolumeType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="颜色">
                    <el-select
                      v-model="tab.color"
                      placeholder="请选择"
                      filterable
                    >
                      <el-option
                        v-for="(item, idx) in $enums_hw.garbageColorType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="品牌">
                    <el-input v-model="tab.brand" placeholder="请输入" maxlength="20"></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="所属项目组">
                    <el-select
                      v-model="tab.projectGroupId"
                      placeholder="请选择"
                      filterable
                    >
                      <el-option
                        v-for="(item, idx) in partnerList"
                        :key="idx"
                        :label="item.partnerShorthand"
                        :value="item.partnerId"
                      />
                    </el-select>
                  </el-form-item>-->
                  <el-form-item label="购买日期" prop="collection">
                    <el-date-picker
                      v-model="tab.buyDate"
                      type="date"
                      value-format="timestamp"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map v-model="mapData" icon="ljzzz"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/mixins/add";
export default {
  mixins: [add],
  data() {
    return {
      nav: [{ name: "新增垃圾中转站" }],
      mapData: {
        //地图数据
        lnglat: null, //经纬度，数组，形如[118,36]
        scope: null //标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      },
      form: {
        transferName: null, //垃圾中转站名称
        bidId: null, //所属标段
        cargoArrivalNumber: null,
        openStartTime: null,
        openEndTime: null,
        transferType: null, //垃圾中转类型
        area: null, //面积
        demandWorker: null, //管理配员
        waterFeeMonthly: null, //每月水费
        electricityFeeMonthly: null, //每月电费
        // transferType:null,//垃圾压缩箱数量
        brand: null, //箱体品牌
        productionDate: null, //出厂日期
        propertyRights: null, //产权
        isSingle: null, //是否单厢
        isContainedWc: null, //含有公厕
        isCompletedFormalities: null, //用地手续是否完备
        highPressureCleanType: null, //高压清洗类型
        address: null, //地址
        transferImageUrls: null, //附件
        compactorList: [
          //垃圾压缩箱
          {
            garbageCompactorId: null, //编号
            type: null, //类别
            volume: null, //容积
            color: null, //颜色
            brand: null, //品牌
            buyDate: null, //购买日期
            projectGroupId: null //所属项目组
          }
        ]
      },
      rules: {
        transferName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: "请输入1-20位垃圾中转站名称",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: {
          required: true,
          message: "请选择",
          trigger: "change"
        },
        propertyRights:{
          required: true,
          message: "请选择",
          trigger: "change"
        },
      },
      compactorListRules: {
        garbageCompactorId: [
          {
            min: 1,
            max: 20,
            required: true,
            message: `请输入1-20位编号`,
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ]
      }
    };
  },
  methods: {
    //新增垃圾箱
    onAddTab() {
      this.form.compactorList.push({
        garbageCompactorId: null, //编号
        type: null, //类别
        volume: null, //容积
        color: null, //颜色
        brand: null, //品牌
        buyDate: null, //购买日期
        projectGroupId: null //所属项目组
      });
    },
    //删除垃圾箱
    onDeleteTab(item) {
      let list = this.form.compactorList;
      let indexTab = list.indexOf(item);
      if (indexTab !== -1 && list.length > 1) {
        //pm要求至少要留1个tab
        list.splice(indexTab, 1);
      }
    },
    /**
     * @summary【钩子函数，函数名固定】
     * @description 提交表单。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async submit() {
      let self = this;
      //提交form数据
      await this.$api_hw.transferStationManagement_insert({
        sanitationRubbishTransferVO: _fixData(self.form)
      });
      //修剪form的数据
      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "isContainedWc":
            case "isSingle":
              d[key] = d[key] * 1;
              break;
          }
        }
        //补字段
        if (self.mapData) {
          if (self.mapData.lnglat)
            d["transferCoordinateScope"] = JSON.stringify(self.mapData.lnglat);
          else
            d["transferCoordinateScope"] = "";
          d["isDrawn"] = self.mapData.lnglat ? 1 : 0;
        }
        self.log(d);
        return d;
      }
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

.add-item
  width: 100%
  // height: 220px
  border: 1px solid rgba(225,225,225,1)
  border-radius: 2px
  margin-bottom: 20px
  .add-item-main
    margin: 10px 0

.add-btn
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
</style>
